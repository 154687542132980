import React, {useEffect} from 'react'
import './../../../../scss/GetCodeModal.scss'
import {ReactSVG} from 'react-svg'
import jsxToString from 'jsx-to-string';
import { renderToString } from 'react-dom/server'
const codeInput = React.createRef();
const GetCodeModal = ({code, isVisible, setIsVisible}) => {
    const [closeBtnTxt, setCloseBtnTxt] = React.useState('copy');

    const closeModal = () => {
        setIsVisible(false);
    }
    const highlight = () => {
        codeInput.current.focus();
        codeInput.current.setSelectionRange(0, codeInput.current.value.length);
        
    }
    const copy = () => {
        highlight();
        document.execCommand('copy');
        setCloseBtnTxt('copied!');
        setTimeout(
            () => {
                setCloseBtnTxt('copy');
            }
        , 2000);
    }
    const icon = <ReactSVG src='/icons/close.svg' />;
    console.log(icon);
    //<ReactSVG src='/icons/close.svg' />
    if(isVisible)
    return(
        <React.Fragment>
            <div className='getCodeModal'>
                <div className='getCodeModal__close' onClick={closeModal}>
                    {icon}
                </div>
                <div className='getCodeModal__txtareaCont'>
                    <textarea
                    className='getCodeModal__txtarea BlockOptions__scroll'
                        ref={codeInput}
                        readOnly
                        onClick={highlight}
                        value={code}
                    >
                    </textarea>
                </div>
                <button
                    className='getCodeModal__closeBtn g__btn'
                    onClick={copy}
                >
                {closeBtnTxt}
                </button>
            </div>

            <div
                className='getCodeModal__overlay'
                onClick={closeModal}
            ></div>
        </React.Fragment>
    )
    else
        return '';
}

export default GetCodeModal;