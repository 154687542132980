import React from 'react'
import StylesOptions from '../../../data/StylesOptions'
import StyleOption from './StyleOption'
import AlignOption from './AlignOption'
const StyleOptions = () => {
    return (
        <div className='StyleOptions OptionsBar__part'>
            <h2 className='OptionsBar__title'>
                style options
            </h2>
            
            <div className='OptionsBar__col BlockOptions__scroll'>
                <div className='BlockOptions__option'>
                    <h4 className='OptionsBar__title2'>choose color</h4>
                    {
                        StylesOptions.map((StylesOption, i) => (
                            <StyleOption
                                StylesOption={StylesOption}
                                key={i}
                                i={i}
                            
                            />
                        ))
                    }
                </div>
                <div className='BlockOptions__option'>
                    <h4 className='OptionsBar__title2'>alignment</h4>
                    <AlignOption />
                </div>
            </div>
        </div>
    )
}

export default StyleOptions