import React from 'react';
import './../../../scss/Preview.scss'
import Generator from './Generator'
import {GlobalStore} from './../../../contexts/GlobalState'
import SocialButtonsStyles from './../../../data/SocialButtonsStyles'
import StylesOptions from './../../../data/StylesOptions'
import GetCodeModal from './GetCode/GetCodeModal'
import './../../../../../src/HTML/assets/css/socialButtons.css'
const Preview = () => {
    const [getCodeModal__isVisible, SetGetCodeModal__isVisible] = React.useState(false);
    const {
        userChoice__style,
        userChoice__color,
        userChoice__socailButtons,
        userChoice__info,
        userChoice__alignCenter

    } = React.useContext(GlobalStore);
    const alignmentClass = userChoice__alignCenter? ' justify-content-center' : '';
    const blockHTML = Generator(
        SocialButtonsStyles[userChoice__style],
        userChoice__socailButtons,
        userChoice__info,
        StylesOptions[userChoice__color].class + alignmentClass
    ).replace(/(\n|\t| )+/g, ' ');
    const openGetCodeModal = () => {
        SetGetCodeModal__isVisible(true)
    }
    return (
        <div className='Preview'>
            <div className='Preview__inner BlockOptions__scroll'>
                <h2 className='Preview__title'>Preview</h2>
                <div className='socialMediaBlock' dangerouslySetInnerHTML={{__html: blockHTML}}>
                </div>
                <button
                    className='g__btn Preview__btn'
                    onClick={openGetCodeModal}
                >get code</button>
            </div>
            <GetCodeModal
                code={blockHTML}
                isVisible={getCodeModal__isVisible}
                setIsVisible={SetGetCodeModal__isVisible}

            />
        </div>
    )
}

export default Preview;