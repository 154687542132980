import React from 'react'
import {GlobalStore} from './../../../contexts/GlobalState'
const StyleOption = ({StylesOption, i}) => {
    const {userChoice__color, dispatchuserChoice__color} = React.useContext(GlobalStore);
    console.log('userChoice__color', userChoice__color);
    const onClick = () => {
        dispatchuserChoice__color({type: 'UPDATE', payload: i})
    }
    return (
        <div className={'StylesOption selectOption' + (userChoice__color == i? ' selected' : '')}
            onClick={onClick}
        >
            {
                StylesOption.txt
            }
        </div>
    )
}

export default StyleOption