import React from 'react'
import InfoForm from './InfoForm'
import {GlobalStore} from './../../../contexts/GlobalState'
import './../../../scss/UserInfo.scss'
const UserInfo = () => {
    const {userChoice__socailButtons} = React.useContext(GlobalStore);
    return (
        <div className='UserInfo OptionsBar__part'>
            <h2 className='OptionsBar__title'>
                choose social media to show
            </h2>
            <div className='OptionsBar__col BlockOptions__scroll'>
                {
                    userChoice__socailButtons.map((socailButton, i) => (
                            <div key={i} className='UserInfo__form'>
                                <h4 className='OptionsBar__title2'>{socailButton} info</h4>
                                <InfoForm
                                    socailButton={socailButton}
                                    key={i}
                                />
                            </div>
                        )
                    )
                }
            </div>
        </div>
    )
}

export default UserInfo