import React, { Component, createContext, useState, useReducer, useEffect } from 'react'
export const GlobalStore = createContext();



const universalRed = (state, action) => {
    switch(action.type){
        case 'UPDATE':
            return action.payload
        default:
            return state;
    }
}

const uniForm = {
    link: '#',
    followersCount: '2k',
    followersTxt: 'followers'
}
const GlobalStoreProvider = (props) => {

    const [userChoice__style, dispatchuserChoice__style] = useReducer(universalRed,0);
    const [userChoice__color, dispatchuserChoice__color] = useReducer(universalRed,  0);
    const [userChoice__socailButtons, dispatchuserChoice__socailButtons] = useReducer(universalRed, ['facebook', 'twitter']);
    const [userChoice__info, dispatchuserChoice__info] = useReducer(universalRed,{
        facebook: JSON.parse(JSON.stringify(uniForm)),
        twitter: JSON.parse(JSON.stringify(uniForm)),
    });

    const [userChoice__alignCenter, dispatchuserChoice__alignCenter] = useReducer(universalRed,  false);

    useEffect(() => {
        let info = {};
        for(let i = 0; i < userChoice__socailButtons.length; i++){
            if(!userChoice__info[userChoice__socailButtons[i]]){
                info[userChoice__socailButtons[i]] = JSON.parse(JSON.stringify(uniForm));
            }else{
                info[userChoice__socailButtons[i]] = JSON.parse(JSON.stringify(userChoice__info[userChoice__socailButtons[i]]));
            }
        }
        dispatchuserChoice__info({type: 'UPDATE', payload: info})
    }, [userChoice__socailButtons]);
    return (
        <GlobalStore.Provider
            value={
                {
                    userChoice__style,
                    dispatchuserChoice__style,
                    userChoice__color,
                    dispatchuserChoice__color,
                    userChoice__socailButtons,
                    dispatchuserChoice__socailButtons,
                    userChoice__info,
                    dispatchuserChoice__info,
                    userChoice__alignCenter,
                    dispatchuserChoice__alignCenter
                }
            }
        >
            {props.children}
        </GlobalStore.Provider>

    )
}

export default GlobalStoreProvider