
import {SocialMediaButtons} from './../../../data/SocailMediaButtons'


const Generator = (style, userChoice__socailButtons, userChoice__info, classes) => {

    const renderFollowersInfo = (btn) => {
        let FollowersInfoHTML = '<span class="SocialButtons__followersInfo">';

        for(let i = 0; i < style.dataNeeded.length; i++){
            if(style.dataNeeded[i] == 'followersCount'){
                FollowersInfoHTML += `<span class='SocialButtons__followersCount'>${userChoice__info[btn].followersCount}</span>`;
            }else if(style.dataNeeded[i] == 'followersTxt'){
                FollowersInfoHTML += `<span class='SocialButtons__followersTxt'>${userChoice__info[btn].followersTxt}</span>`;

            }
        }
        FollowersInfoHTML += '</span>';

        return FollowersInfoHTML;
    }
    const basicSB = (basicSB__classes) => {
        let socialButtons = '';
    
        for(let i = 0; i < userChoice__socailButtons.length; i++){
            if(userChoice__info[userChoice__socailButtons[i]]){
                socialButtons += `
                <a class='SocialButtons__el SocialButtons__${userChoice__socailButtons[i]}' target='_blank' href='${userChoice__info[userChoice__socailButtons[i]].link}' >
                    ${SocialMediaButtons[userChoice__socailButtons[i]]}



                    ${renderFollowersInfo(userChoice__socailButtons[i])}
                </a>`;
            }
        }
        return `
            <div class='SocialButtons d-flex ${basicSB__classes}'>
                ${socialButtons}
            </div>
        `
    }
    const blocks = {
        SocialButtonsStyle1: function(){
            return basicSB('SocialButtonsStyle1 ' + classes);
        },
        SocialButtonsStyle2: function(){
            return basicSB('SocialButtonsStyle2 ' + classes);
        },
        SocialButtonsStyle3: function(){
            return basicSB('SocialButtonsStyle3 ' + classes);
        },
        SocialButtonsStyle4: function(){
            return basicSB('SocialButtonsStyle4 ' + classes);
        },
        SocialButtonsStyle5: function(){
            return basicSB('SocialButtonsStyle5 ' + classes);
        },
        SocialButtonsStyle6: function(){
            return basicSB('SocialButtonsStyle6 SB__vCardStyle ' + classes);
        },
        SocialButtonsStyle7: function(){
            return basicSB('SocialButtonsStyle7 SB__vCardStyle ' + classes);
        },
        SocialButtonsStyle8: function(){
            return basicSB('SocialButtonsStyle8 SB__vCardStyle ' + classes);
        },
        SocialButtonsStyle9: function(){
            return basicSB('SocialButtonsStyle9 SB__vCardStyle ' + classes);
        },
        SocialButtonsStyle10: function(){
            return basicSB('SocialButtonsStyle10 SB__hCardStyle ' + classes);
        },
        SocialButtonsStyle11: function(){
            return basicSB('SocialButtonsStyle11 SB__hCardStyle ' + classes);
        },
        SocialButtonsStyle12: function(){
            return basicSB('SocialButtonsStyle12 SB__hCardStyle ' + classes);
        },

    }

    return blocks[style.name]();
}
export default Generator;