
import React from 'react'
import {GlobalStore} from './../../../contexts/GlobalState'
const AlignOption = () => {
    const {userChoice__alignCenter, dispatchuserChoice__alignCenter} = React.useContext(GlobalStore);
    const onClick = (e) => {
        dispatchuserChoice__alignCenter({type: 'UPDATE', payload: (e.target.getAttribute('value') == '1')});
    }
    return (
        <>
        <div className={'StylesOption selectOption' + (userChoice__alignCenter? '' : ' selected')}
            onClick={onClick} value='0'
        >
        none
        </div>

        <div className={'StylesOption selectOption' + (userChoice__alignCenter? ' selected' : '')}
            onClick={onClick} value='1'
        >
        center
        </div>

        </>
    )
}

export default AlignOption