import React from 'react'
import SocialButton from './SocialButton'
import {SocialMediaButtons} from './../../../data/SocailMediaButtons'
const SocialButtons = () => {
    const render__SocialMediaButtons = () => {
        let SocialMediaButtonsHTML = [];
        let i = 0;
        for(let name in SocialMediaButtons){
            SocialMediaButtonsHTML.push(
                <SocialButton
                    name={name}
                    socialButton={SocialButtons[name]}
                    i={i}
                    key={i}
                />
            );
            i++;
        }
        return SocialMediaButtonsHTML;
    }
    return (
        <div className='SocialButtonsOptions OptionsBar__part'>
            <h2 className='OptionsBar__title'>
                choose social media to show
            </h2>
            <div className='OptionsBar__col BlockOptions__scroll'>
                {render__SocialMediaButtons()}
            </div>
            
        </div>
    )
}

export default SocialButtons