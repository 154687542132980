import React from 'react'
import {GlobalStore} from './../../../contexts/GlobalState'
const SocialButton = ({socialButton, name}) => {
    const {userChoice__socailButtons, dispatchuserChoice__socailButtons} = React.useContext(GlobalStore);
    const [isSelected, setIsSelected ]= React.useState(false);
    console.log('userChoice__socailButtons', userChoice__socailButtons);
    const onClick = () => {
        let newState = userChoice__socailButtons.slice();
        if(isSelected){
            for(let i = 0; i < newState.length; i++){
                if(name == newState[i]){
                    newState.splice(i, 1);
                    break;
                }
            }
        }else{
            newState.push(name);
        }


        dispatchuserChoice__socailButtons({type: 'UPDATE', payload:  newState});
    }

    React.useEffect(() => {
        console.log('update');
        let buttonExists = false;
        let socailButtons = userChoice__socailButtons;
        for(let i = 0; i < socailButtons.length; i++){
            console.log('socailButtons[i]', socailButtons[i]);
            if(name == socailButtons[i]){
                buttonExists = true;
                break;
            }
        }
        setIsSelected(buttonExists);
    }, [userChoice__socailButtons]);
    return (
        <div
            className={'SocialButton selectOption' + (isSelected? ' selected':'') }
            onClick={onClick}
        >
            {name}
        </div>
    )   
}

export default SocialButton