import React from 'react';
import OptionsBar from './OptionsBar'
import Preview from './OptionsBar/Preview/Preview'
import './../scss/global.scss'
import './../scss/SocialButtonsBuilder.scss'
import GlobalStoreProvider from './../contexts/GlobalState'
const SocialButtonsBuilder = () => {
    function noop() {}
    if (process.env.NODE_ENV !== 'development') {
        console.log = noop;
        console.warn = noop;
    }
    return (
        <GlobalStoreProvider>
            <div className='SocialButtonsBuilder'>
                <Preview />
                <OptionsBar />
            </div>
        </GlobalStoreProvider>
    )
}

export default SocialButtonsBuilder;