const StylesOptions =[
    {
        txt: 'link color',
        class: 'SocialButtons__linkClr'
    },
    {
        txt: 'text color',
        class: 'SocialButtons__txtClr'
    },
    {
        txt: 'original color',
        class: 'SocialButtons__originalClr'
    },
];
export default StylesOptions;