const {SocialButtonsStyle} = require('./../core/SocialButtonsStyleClass');
console.log('SocialButtonsStyle', SocialButtonsStyle);
const SocialButtonsStyles = [];

SocialButtonsStyles.push(
    new SocialButtonsStyle('SocialButtonsStyle1',
        ['link']
    )
);

SocialButtonsStyles.push(
    new SocialButtonsStyle('SocialButtonsStyle2',
        ['link']
    )
);

SocialButtonsStyles.push(
    new SocialButtonsStyle('SocialButtonsStyle3',
        ['link']
    )
);
SocialButtonsStyles.push(
    new SocialButtonsStyle('SocialButtonsStyle4',
        ['link']
    )
);
SocialButtonsStyles.push(
    new SocialButtonsStyle('SocialButtonsStyle5',
        ['link']
    )
);
SocialButtonsStyles.push(
    new SocialButtonsStyle('SocialButtonsStyle6',
        ['link', 'followersCount', 'followersTxt']
    )
);

SocialButtonsStyles.push(
    new SocialButtonsStyle('SocialButtonsStyle7',
        ['link', 'followersCount', 'followersTxt']
    )
);

SocialButtonsStyles.push(
    new SocialButtonsStyle('SocialButtonsStyle8',
        ['link', 'followersCount']
    )
);

SocialButtonsStyles.push(
    new SocialButtonsStyle('SocialButtonsStyle9',
        ['link', 'followersCount']
    )
);

SocialButtonsStyles.push(
    new SocialButtonsStyle('SocialButtonsStyle10',
        ['link', 'followersCount', 'followersTxt']
    )
);
SocialButtonsStyles.push(
    new SocialButtonsStyle('SocialButtonsStyle11',
        ['link', 'followersCount', 'followersTxt']
    )
);
SocialButtonsStyles.push(
    new SocialButtonsStyle('SocialButtonsStyle12',
        ['link', 'followersCount', 'followersTxt']
    )
);
export default SocialButtonsStyles;