import React from "react"
import SocialButtonsBuilder from './../components/SocialButtonsBuilder'
import { Helmet } from "react-helmet"
export default () => {
    return(
        <>
            <Helmet>
                <meta charset="UTF-8" />
                <meta name="viewport" content="width=device-width, initial-scale=1.0" />
                <meta http-equiv="X-UA-Compatible" content="ie=edge" />
                <link rel="shortcut icon" type="image/png" href="favicon.png" />
                <title>Social Media Buttons Builder</title>
            </Helmet>
            <SocialButtonsBuilder />
        </>
    )
    
}
