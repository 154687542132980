import React from 'react'
import {GlobalStore} from './../../../contexts/GlobalState'
import Generator from './../Preview/Generator'
import StylesOptions from './../../../data/StylesOptions'

const demo__socailButtons = ['facebook', 'youtube' ,'twitter'];
const demo__userInfo = {
    facebook: {
        link: "#",
        followersCount: "2k",
        followersTxt: "likes"
    },
    youtube: {
        link: "#",
        followersCount: "2k",
        followersTxt: "subs"
    },
    twitter: {
        link: "#",
        followersCount: "2k",
        followersTxt: "followers"
    }
}
const Style = ({style, i}) => {
    const {userChoice__style, dispatchuserChoice__style, userChoice__color} = React.useContext(GlobalStore);
    console.log('userChoice__style', userChoice__style);
    console.log('i', i);
    const onClick = () => {
        dispatchuserChoice__style({type: 'UPDATE', payload: i});
    }
    const blockHTML = Generator(
        style,
        demo__socailButtons,
        demo__userInfo,
        StylesOptions[userChoice__color].class
    );
    return (
        <div
            className={'Styles__Style selectOption' + ((i == userChoice__style)? ' selected' : '')}
            onClick={onClick}
        >
            <div className='socialMediaBlock' dangerouslySetInnerHTML={{__html: blockHTML}} />
        </div>
    )
}

export default Style