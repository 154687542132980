import React from 'react';
import './../scss/OptionsBar.scss'
import Styles from './OptionsBar/Styles/Styles'
import StyleOptions from './OptionsBar/StyleOptions/StyleOptions'
import SocialButtons from './OptionsBar/SocialButtons/SocialButtons'
import UserInfo from './OptionsBar/UserInfo/UserInfo'
const OptionsBar = () => {
    return (
        <div className='OptionsBar'>
            <Styles />
            <StyleOptions />  
            <SocialButtons />  
            <UserInfo />
        </div>
    )
}

export default OptionsBar;