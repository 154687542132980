import React from 'react'
import SocialButtonsStyles from './../../../data/SocialButtonsStyles'
import Style from './Style'
import './../../../scss/Styles.scss'
const Styles = () => {
    return (
        <div className='Styles OptionsBar__part'>
            <h2 className='OptionsBar__title'>
                choose a style
            </h2>
            <div className='OptionsBar__col BlockOptions__scroll'>
                {
                SocialButtonsStyles.map((SocialButtonsStyle, i) => (
                            <Style style={SocialButtonsStyle} key={i} i={i} />
                        )
                    )
                }
            </div>
            
        </div>
    )
}

export default Styles