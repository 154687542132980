import React from 'react'
import {GlobalStore} from './../../../contexts/GlobalState'
import SocialButtonsStyles from './../../../data/SocialButtonsStyles'
const inputMsg = {
    link: 'link',
    followersCount: 'followers count',
    followersTxt: 'followers text'
}
const InfoForm = ({socailButton}) => {
    const {userChoice__info, dispatchuserChoice__info, userChoice__style} = React.useContext(GlobalStore);
    console.log('userChoice__info', userChoice__info);

    const renderInput = (name, value) => {

        return(
            <div className='UserInfo__formRow'>
                <label>
                    <p className='UserInfo__inputTitle'>{inputMsg[name]}</p>
                    <input
                        type='text'
                        value={value}
                        name={name}
                        onChange={onChange}
                        className='UserInfo__input'
                    />
                </label>
            </div>
        )
    }

    const onChange = (e) => {
        let info = JSON.parse(JSON.stringify(userChoice__info));
        info[socailButton][e.target.name] = e.target.value;

        dispatchuserChoice__info({type: 'UPDATE', payload: info});
    }
    const renderInputs = () => {
        console.log('SocialButtonsStyles', SocialButtonsStyles);
        let inputsHTML = [];
        const dataNeeded = SocialButtonsStyles[userChoice__style].dataNeeded;
        for(let i = 0; i < dataNeeded.length; i++){
            if(userChoice__info[socailButton])
            inputsHTML.push(
                <React.Fragment key={i}>
                    {renderInput(dataNeeded[i], userChoice__info[socailButton][dataNeeded[i]])}
                </React.Fragment>
                );
        }
        return inputsHTML;
    }
    return (
        <div className='InfoForm'>
            {renderInputs()}
        </div>
    )
}

export default InfoForm